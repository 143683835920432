import { ReactNode } from 'react'
import { cn } from '@/utils/block'

export const cnLayout = cn('layout')

export type LayoutProps = {
  children?: ReactNode
  className?: string
}

export function Layout({ children, className }: LayoutProps): JSX.Element {
  return <div className={cnLayout({}, [className])}>{children}</div>
}
