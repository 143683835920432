import { cnCard } from '@/blocks/card'
import { ReactNode } from 'react'
import style from './card__content.module.sass'
import { styled } from '@/utils/block'

styled(style)

type CardContentProps = {
  children?: ReactNode
}

export function CardContent({ children }: CardContentProps): JSX.Element {
  return <div className={cnCard('content')}>{children}</div>
}
