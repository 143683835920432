import { cnMapControl, MapControl, MapControlProps } from '@/blocks/map-control'

export function MapControlZoomOut({ onClick, disabled }: MapControlProps): JSX.Element {
  return (
    <MapControl className={cnMapControl({ 'zoom-out': true, disabled })} onClick={onClick}>
      <div className={cnMapControl('icon')}>
        <svg
          width="16"
          height="4"
          viewBox="0 0 16 4"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.2427 1.86377L1.75002 1.86377"
            stroke="#E03527"
            strokeWidth="3"
            strokeLinecap="round"
          />
        </svg>
      </div>
    </MapControl>
  )
}
