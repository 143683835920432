import { cnMenu } from '@/blocks/menu'
import { Link, LinkProps } from 'react-router-dom'
import style from './menu__item.module.sass'
import { styled } from '@/utils/block'

styled(style)

type MenuItemProps = {
  active?: boolean
} & LinkProps

export function MenuItem({ to, children, active, onClick }: MenuItemProps): JSX.Element {
  return (
    <div className={cnMenu('item', { active })}>
      <Link to={to} onClick={onClick} className={cnMenu('item-inner')}>
        {children}
      </Link>
    </div>
  )
}
