import { ReactNode } from 'react'
import { cn } from '@/utils/block'

export const cnHeader = cn('header')

export type HeaderProps = {
  children?: ReactNode
  className?: string
}

export function Header({ children, className }: HeaderProps): JSX.Element {
  return <div className={cnHeader({}, [className])}>{children}</div>
}
