import { cnCard } from '@/blocks/card'
import { ButtonDefault, ButtonDefaultProps } from '@/blocks/button/_default'
import style from './card__close.module.sass'
import { styled } from '@/utils/block'

styled(style)

type CardCloseProps = {
  icon?: boolean
} & ButtonDefaultProps

export function CardClose({ children, icon, ...rest }: CardCloseProps): JSX.Element {
  return (
    <ButtonDefault {...rest} className={cnCard('close')}>
      <span>{children}</span>
      <div className={cnCard('icon')}>
        <svg className={cnCard('icon-svg')}
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="24" cy="24" r="23.5" stroke="#53CBFF" strokeOpacity="0.3" />
          <path
            d="M19 19L29 29M19 29L29 19"
            stroke="#E21A1A"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    </ButtonDefault>
  )
}
