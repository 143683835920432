import { cn, styled } from '@/utils/block'
import { ReactNode, useContext, useEffect, useState } from 'react'
import style from './theme.module.sass'
import styleDark from './theme_dark.module.sass'
import styleLight from './theme_light.module.sass'
import { ThemeContext } from './theme.context'

styled(style)
styled(styleDark)
styled(styleLight)

export const cnTheme = cn('theme')

export type ThemeProps = {
  children?: ReactNode
}

export function Theme({ children }: ThemeProps): JSX.Element {
  const [theme, setTheme] = useContext(ThemeContext)
  const [loaded, setLoaded] = useState<boolean>(false)
  const body = document.querySelector('body')

  // @ts-ignore
  const handleChangeTheme = (e) => {
    setTheme((prevTheme: string) => {
      body?.classList.remove(`body-theme_${prevTheme}`)
      return e.detail.theme
    })
  }

  // @ts-ignore
  const handleMessage = (event) => {
    if (event.data === 'dark' || event.data === 'light') {
      setTheme(event.data)
    }
    if (event.data === 'night') {
      setTheme('dark')
    }
    if (event.data === 'day') {
      setTheme('light')
    }
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const urlTheme = urlParams.get('theme')
    if (urlTheme === 'dark' || urlTheme === 'light') {
      setTheme(urlTheme)
    }
    if (urlTheme === 'night') {
      setTheme('dark')
    }
    if (urlTheme === 'day') {
      setTheme('light')
    }
    setLoaded(true)
  }, [])

  useEffect(() => {
    body?.classList.remove(`body-theme_${theme === 'dark' ? 'light' : 'dark'}`)
    body?.classList.add(`body-theme_${theme}`)
  }, [theme])

  useEffect(() => {
    body?.classList.add('body-theme', `body-theme_${theme}`)
    document.addEventListener('bamChangeTheme', handleChangeTheme)
    window.addEventListener('message', handleMessage)

    return () => {
      body?.classList.remove('body-theme', `body-theme_${theme}`)
      document.removeEventListener('bamChangeTheme', handleChangeTheme)
      window.removeEventListener('message', handleMessage)
    }
  }, [])

  if (!theme || !loaded) <></>

  return <div className={cnTheme({ [theme as string]: !!theme })}>{children}</div>
}
