import { cnCard } from '@/blocks/card'
import { ReactNode } from 'react'
import style from './card__title.module.sass'
import { styled } from '@/utils/block'

styled(style)

type CardTitleProps = {
  children?: ReactNode
  second?: boolean
}

export function CardTitle({ children, second }: CardTitleProps): JSX.Element {
  return <div className={cnCard('title', { second })}>{children}</div>
}
