import { cnCard } from '@/blocks/card'
import { ReactNode } from 'react'
import style from './card__header.module.sass'
import { styled } from '@/utils/block'

styled(style)

type CardHeaderProps = {
  children?: ReactNode
}

export function CardHeader({ children }: CardHeaderProps): JSX.Element {
  return <div className={cnCard('header')}>{children}</div>
}
