import style from './map-control.module.sass'
import { cn, styled } from '@/utils/block'
import { Button, ButtonProps } from '@/blocks/button'

styled(style)

export type MapControlProps = {
  className?: string
  disabled?: boolean
} & ButtonProps

export const cnMapControl = cn('map-control')

export function MapControl({ className, disabled, ...rest }: MapControlProps): JSX.Element {
  return <Button {...rest} className={cnMapControl({ disabled }, [className])} />
}
