import { cnHeader, HeaderProps } from '@/blocks/header'
import { HeaderDefault } from '@/blocks/header/_default'
import { ReactNode } from 'react'
import style from './header_top.module.sass'
import { styled } from '@/utils/block'

styled(style)

export type HeaderTopProps = {
  title?: string
  children?: ReactNode
} & HeaderProps

export function HeaderTop({ children, className, title }: HeaderTopProps): JSX.Element {
  return (
    <HeaderDefault className={cnHeader({ top: true }, [className])}>
      {title && <div className={cnHeader('title')}>{title}</div>}
      {children}
    </HeaderDefault>
  )
}
