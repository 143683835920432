import { cnMenu, Menu, MenuProps } from '@/blocks/menu'
import style from './menu_atlas.module.sass'
import { MenuItem } from './__item'
import { styled } from '@/utils/block'

styled(style)

export function MenuAtlas({ children, className }: MenuProps) {
  return <Menu className={cnMenu({ atlas: true }, [className])}>{children}</Menu>
}

MenuAtlas.Item = MenuItem
