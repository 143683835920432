import { cnMapControl, MapControl, MapControlProps } from '@/blocks/map-control'

export function MapControlZoomIn({ onClick }: MapControlProps): JSX.Element {
  return (
    <MapControl className={cnMapControl({ 'zoom-in': true })} onClick={onClick}>
      <div className={cnMapControl('icon')}>
        <svg
          width="16"
          height="16"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7 1V7M7 13V7M7 7H13M7 7H1"
            stroke="#E21A1A"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    </MapControl>
  )
}
