import maplibregl from 'maplibre-gl'
import { createContext, ReactNode } from 'react'

type ProviderProps = {
  map: maplibregl.Map | undefined
  children: ReactNode
}

type ContextProps = maplibregl.Map | undefined

export const MapContext = createContext<ContextProps>(undefined)

export function MapProvider({ map, children }: ProviderProps): JSX.Element {
  return <MapContext.Provider value={map}>{children}</MapContext.Provider>
}
