import { createContext, ReactNode, useState } from 'react'

type ProviderProps = {
  children: ReactNode
}

export type ThemeContextProps = ['dark' | 'light' | undefined, any]

export const ThemeContext = createContext<ThemeContextProps>([undefined, () => {}])

export function ThemeProvider({ children }: ProviderProps): JSX.Element {
  const [theme, setTheme] = useState<'dark' | 'light' | undefined>(undefined)
  return <ThemeContext.Provider value={[theme, setTheme]}>{children}</ThemeContext.Provider>
}
