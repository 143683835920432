import { cnHeader, HeaderProps } from '@/blocks/header'
import { ReactNode } from 'react'
import style from './header_atlas.module.sass'
import { styled } from '@/utils/block'
import { HeaderTop } from '@/blocks/header/_top'

styled(style)

export type HeaderAtlasProps = {
  title?: string
  children?: ReactNode
} & HeaderProps

export function HeaderAtlas({ children, className, title }: HeaderAtlasProps): JSX.Element {
  return (
    <HeaderTop className={cnHeader({ atlas: true }, [className])} title={title}>
      {children}
    </HeaderTop>
  )
}
