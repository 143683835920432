import { cnCard } from '@/blocks/card'
import { ReactNode } from 'react'
import style from './card__space.module.sass'
import { styled } from '@/utils/block'

styled(style)

type CardSpaceProps = {
  children?: ReactNode
  size?: number | string
  right?: number
  className?: string
}

export function CardSpace({
  children,
  size = 'auto',
  right = 0,
  className,
}: CardSpaceProps): JSX.Element {
  return (
    <div className={cnCard('space', {}, [className])} style={{ height: size, paddingRight: right }}>
      {children}
    </div>
  )
}
