import style from './map.module.sass'
import maplibregl, { MapLibreEvent, StyleSpecification } from 'maplibre-gl'
import 'maplibre-gl/dist/maplibre-gl.css'
import { memo, ReactNode, useEffect, useRef, useState } from 'react'
import { cn, styled } from '@/utils/block'

styled(style)

const cnMap = cn('map')

type MapProps = {
  initialOptions?: Omit<maplibregl.MapOptions, 'container'>
  className?: string
  children?: ReactNode
  style?: StyleSpecification | string
  onCreated?(map: maplibregl.Map): void
  onLoaded?(map: maplibregl.Map): void
  onZoomed?(e: MapLibreEvent<MouseEvent | TouchEvent | WheelEvent | undefined> & Object): void
  onZoom?(e: MapLibreEvent<MouseEvent | TouchEvent | WheelEvent | undefined> & Object): void
  onRemoved?(): void
}

const defaultOptions = {
  hash: false,
  attributionControl: true,
  renderWorldCopies: false,
}

export const Map = memo(function Map({
  initialOptions,
  onCreated,
  onLoaded,
  onRemoved,
  className,
  onZoomed,
  onZoom,
  style,
  children,
}: MapProps): JSX.Element {
  const [map, setMap] = useState<maplibregl.Map>()
  const [firstEvent, setFirstEvent] = useState<MouseEvent | TouchEvent | WheelEvent>()

  const mapNode = useRef(null)

  useEffect(() => {
    const node = mapNode.current

    if (typeof window === 'undefined' || node === null) return
    // @ts-ignore
    maplibregl.useExperimetalFeatures = true

    const libMap = new maplibregl.Map({
      style: 'https://demotiles.maplibre.org/style.json',
      container: node,
      ...defaultOptions,
      ...initialOptions,
    })

    // disable map rotation using right click + drag
    libMap.dragRotate.disable()

    // disable map rotation using touch rotation gesture
    libMap.touchZoomRotate.disableRotation()

    libMap.on('click', (e) => {
      console.log([e.lngLat.lng, e.lngLat.lat])
    })

    setMap(libMap)
    if (onCreated) onCreated(libMap)

    if (onLoaded) libMap.once('load', () => onLoaded(libMap))

    return () => {
      libMap.remove()
      if (onRemoved) onRemoved()
      setMap(undefined)
    }
  }, [])

  useEffect(() => {
    if (!style) return
    map?.setStyle(style as StyleSpecification)
  }, [style])

  const handleZoomEnd = (
    e: MapLibreEvent<MouseEvent | TouchEvent | WheelEvent | undefined> & Object
  ) => {
    onZoomed && onZoomed(e)
  }

  const handleZoom = (
    e: MapLibreEvent<MouseEvent | TouchEvent | WheelEvent | undefined> & Object
  ) => {
    onZoom && onZoom(e)
  }

  const handleMoveStart = ({ originalEvent }: MapLibreEvent) => {
    if (!firstEvent && !originalEvent) return
    if (originalEvent) {
      setFirstEvent(originalEvent)
      map?.fire('usermovestart')
    } else {
      map?.fire('flystart')
    }
  }

  const handleMoveEnd = ({ originalEvent }: MapLibreEvent) => {
    if (!firstEvent && !originalEvent) return
    if (originalEvent) {
      setFirstEvent(originalEvent)
      map?.fire('usermoveend')
    } else {
      map?.fire('flyend')
    }
  }

  useEffect(() => {
    map?.on('zoomend', handleZoomEnd)
    map?.on('zoom', handleZoom)
    map?.on('movestart', handleMoveStart)
    map?.on('moveend', handleMoveEnd)

    return () => {
      map?.off('zoomend', handleZoomEnd)
      map?.off('zoom', handleZoom)
      map?.off('movestart', handleMoveStart)
      map?.off('moveend', handleMoveEnd)
    }
  })

  const classes = cnMap({}, ['maplibregl-map', className])

  return (
    <div ref={mapNode} className={classes} style={{ width: '100%', height: '100%' }}>
      {children}
    </div>
  )
})
