import { CardDefault, CardDefaultProps } from '@/blocks/card/_default'
import { cnCard } from '@/blocks/card'
import style from './card_modal.module.sass'
import { styled } from '@/utils/block'
import { CardHeader } from './__header'
import { CardClose } from './__close'
import { CardContent } from './__content'
import { CardTitle } from './__title'
import { CardText } from './__text'
import { CardLine } from './__line'
import { CardGrid } from './__grid'
import { CardSpace } from './__space'

styled(style)

export type CardModalProps = {
  toRight?: boolean
  atlas?: boolean
} & CardDefaultProps

export function CardModal({ children, toRight, atlas, className }: CardModalProps): JSX.Element {
  return (
    <CardDefault className={cnCard({ modal: true, 'to-right': toRight, atlas }, [className])}>
      {children}
    </CardDefault>
  )
}

CardModal.Header = CardHeader
CardModal.Close = CardClose
CardModal.Content = CardContent
CardModal.Title = CardTitle
CardModal.Line = CardLine
CardModal.Text = CardText
CardModal.Grid = CardGrid
CardModal.Space = CardSpace
