import { cn } from '@/utils/block'
import { ReactNode } from 'react'

export const cnCard = cn('card')

export type CardProps = {
  children?: ReactNode
  className?: string
}

export function Card({ children, className }: CardProps): JSX.Element {
  return <div className={cnCard({}, [className])}>{children}</div>
}
