import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { ThemeProvider, Theme } from '@/blocks/theme'
import '@/styles/globals.sass'
import ReactModal from 'react-modal'
import { Route, Routes } from 'react-router'
import { BrowserRouter } from 'react-router-dom'
import { PageHistory } from '@/blocks/page/_history'
import { PageAtlasGruzoviePerevozki } from '@/blocks/page/_atlas/_gruzovie-perevozki'
import { PageAtlasPodgotovkaKadrov } from '@/blocks/page/_atlas/_podgotovka-kadrov'
import { LayoutAtlas } from '@/blocks/layout/_atlas'
import { PageAtlasZheleznayaDoroga } from '@/blocks/page/_atlas/_zheleznaya-doroga'
import { PageAtlasPoligonnyhPrincipy } from '@/blocks/page/_atlas/_poligonnyh-principy'
import { PageAtlasKlimatRossii } from '@/blocks/page/_atlas/_klimat-rossii'
import { PageAtlasPassazhirskiePerevozki } from '@/blocks/page/_atlas/_passazhirskie-perevozki'
import { PageAtlasToplivnoEnergResursy } from '@/blocks/page/_atlas/_toplivno-energ-resursy'
import { PageAtlasElektroenergetika } from '@/blocks/page/_atlas/_elektroenergetika'
import { PageAtlasMineralnyeResursy } from '@/blocks/page/_atlas/_mineralnye-resursy'
import { PageAtlasIstoricheskoeNasledie } from '@/blocks/page/_atlas/_istoricheskoe-nasledie'
import { PageAtlasSnezhnyjPokrovChisloDnei } from '@/blocks/page/_atlas/_snezhnyj-pokrov-chislo-dnei'
import { PageAtlasSnezhnyjPokrovVisota } from '@/blocks/page/_atlas/_snezhnyj-pokrov-visota'
import { PageAtlasSnezhnyjPokrovOtlojenia } from '@/blocks/page/_atlas/_snezhnyj-pokrov-otlojenia'
import { PageAtlasSnezhnyjPokrovMeteli } from '@/blocks/page/_atlas/_snezhnyj-pokrov-meteli'
import { PageAtlasTemperaturySredVozduha } from '@/blocks/page/_atlas/_temperatury-sred-vozduha'
import { PageObjects } from '@/blocks/page/_objects'
import { PageAtlasTemperaturyMinSredVozduha } from '@/blocks/page/_atlas/_temperatury-min-sred-vozduha'
import { PageAtlasTemperaturyMaxSredVozduha } from '@/blocks/page/_atlas/_temperatury-max-sred-vozduha'
import { PageAtlasTemperaturySredMenee8 } from '@/blocks/page/_atlas/_temperatury-sred-menee-8'
import { PageAtlasElektrifikaciya } from '@/blocks/page/_atlas/_elektrifikaciya'
import { PageAtlasPutevoeHozyajtvo } from '@/blocks/page/_atlas/_putevoe-hozyajstvo'
import { PageAtlasProizvodstvoProdukcii } from '@/blocks/page/_atlas/_proizvodstvo-produkcii'
import { PageAtlasPrigorodnoePassazhSoobshchenie } from '@/blocks/page/_atlas/_prigorodnoe-passazh-soobshchenie'

ReactModal.setAppElement('#modals')

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <div className={'app'}>
    <ThemeProvider>
      <Theme>
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                <Suspense fallback={<div />}>
                  <PageHistory />
                </Suspense>
              }
            />
            <Route
              path="/objects"
              element={
                <Suspense fallback={<div />}>
                  <PageObjects />
                </Suspense>
              }
            />
            <Route element={<LayoutAtlas />}>
              <Route
                path="/atlas/zheleznaya-doroga"
                element={
                  <Suspense fallback={<div />}>
                    <PageAtlasZheleznayaDoroga />
                  </Suspense>
                }
              />
              <Route
                path="/atlas/podgotovka-kadrov"
                element={
                  <Suspense fallback={<div />}>
                    <PageAtlasPodgotovkaKadrov />
                  </Suspense>
                }
              />
              <Route
                path="/atlas/gruzovie-perevozki"
                element={
                  <Suspense fallback={<div />}>
                    <PageAtlasGruzoviePerevozki />
                  </Suspense>
                }
              />
              <Route
                path="/atlas/poligonnyh-principy"
                element={
                  <Suspense fallback={<div />}>
                    <PageAtlasPoligonnyhPrincipy />
                  </Suspense>
                }
              />
              <Route
                path="/atlas/istoricheskoe-nasledie"
                element={
                  <Suspense fallback={<div />}>
                    <PageAtlasIstoricheskoeNasledie />
                  </Suspense>
                }
              />
              <Route
                path="/atlas/klimat-rossii"
                element={
                  <Suspense fallback={<div />}>
                    <PageAtlasKlimatRossii />
                  </Suspense>
                }
              />
              <Route
                path="/atlas/passazhirskie-perevozki"
                element={
                  <Suspense fallback={<div />}>
                    <PageAtlasPassazhirskiePerevozki />
                  </Suspense>
                }
              />
              <Route
                path="/atlas/toplivno-energ-resursy"
                element={
                  <Suspense fallback={<div />}>
                    <PageAtlasToplivnoEnergResursy />
                  </Suspense>
                }
              />
              <Route
                path="/atlas/elektroenergetika"
                element={
                  <Suspense fallback={<div />}>
                    <PageAtlasElektroenergetika />
                  </Suspense>
                }
              />
              <Route
                path="/atlas/mineralnye-resursy"
                element={
                  <Suspense fallback={<div />}>
                    <PageAtlasMineralnyeResursy />
                  </Suspense>
                }
              />
              <Route
                path="/atlas/snezhnyj-pokrov-chislo-dnei"
                element={
                  <Suspense fallback={<div />}>
                    <PageAtlasSnezhnyjPokrovChisloDnei />
                  </Suspense>
                }
              />
              <Route
                path="/atlas/snezhnyj-pokrov-visota"
                element={
                  <Suspense fallback={<div />}>
                    <PageAtlasSnezhnyjPokrovVisota />
                  </Suspense>
                }
              />
              <Route
                path="/atlas/snezhnyj-pokrov-otlojenia"
                element={
                  <Suspense fallback={<div />}>
                    <PageAtlasSnezhnyjPokrovOtlojenia />
                  </Suspense>
                }
              />
              <Route
                path="/atlas/snezhnyj-pokrov-meteli"
                element={
                  <Suspense fallback={<div />}>
                    <PageAtlasSnezhnyjPokrovMeteli />
                  </Suspense>
                }
              />
              <Route
                path="/atlas/temperatury-sred-vozduha"
                element={
                  <Suspense fallback={<div />}>
                    <PageAtlasTemperaturySredVozduha />
                  </Suspense>
                }
              />
              <Route
                path="/atlas/temperatury-min-sred-vozduha"
                element={
                  <Suspense fallback={<div />}>
                    <PageAtlasTemperaturyMinSredVozduha />
                  </Suspense>
                }
              />
              <Route
                path="/atlas/temperatury-max-sred-vozduha"
                element={
                  <Suspense fallback={<div />}>
                    <PageAtlasTemperaturyMaxSredVozduha />
                  </Suspense>
                }
              />
              <Route
                path="/atlas/temperatury-sred-menee-8"
                element={
                  <Suspense fallback={<div />}>
                    <PageAtlasTemperaturySredMenee8 />
                  </Suspense>
                }
              />
              <Route
                path="/atlas/elektrifikaciya"
                element={
                  <Suspense fallback={<div />}>
                    <PageAtlasElektrifikaciya />
                  </Suspense>
                }
              />
              <Route
                path="/atlas/elektrifikaciya"
                element={
                  <Suspense fallback={<div />}>
                    <PageAtlasElektrifikaciya />
                  </Suspense>
                }
              />
              <Route
                path="/atlas/putevoe-hozyajstvo"
                element={
                  <Suspense fallback={<div />}>
                    <PageAtlasPutevoeHozyajtvo />
                  </Suspense>
                }
              />
              <Route
                path="/atlas/proizvodstvo-produkcii"
                element={
                  <Suspense fallback={<div />}>
                    <PageAtlasProizvodstvoProdukcii />
                  </Suspense>
                }
              />
              <Route
                path="/atlas/prigorodnoe-passazh-soobshchenie"
                element={
                  <Suspense fallback={<div />}>
                    <PageAtlasPrigorodnoePassazhSoobshchenie />
                  </Suspense>
                }
              />
            </Route>
          </Routes>
        </BrowserRouter>
      </Theme>
    </ThemeProvider>
  </div>
)
