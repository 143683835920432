import style from './map-space.module.sass'
import { cn, styled } from '@/utils/block'
import { ReactNode } from 'react'

styled(style)

export const cnMapSpace = cn('map-space')

export type MapSpaceProps = {
  position?:
    | 'right-top'
    | 'right-center'
    | 'right-bottom'
    | 'left-top'
    | 'left-center'
    | 'left-bottom'
    | 'bottom-center'
  direction?: 'horizontal' | 'vertical'
  pressed?: boolean
  full?: boolean
  children?: ReactNode
  className?: string
}

export function MapSpace({
  position = 'right-center',
  direction = 'horizontal',
  pressed,
  full,
  children,
  className,
}: MapSpaceProps): JSX.Element {
  return (
    <div
      className={cnMapSpace(
        {
          [position]: !!position,
          [direction]: !!direction,
          pressed,
          full,
        },
        [className]
      )}
    >
      {children}
    </div>
  )
}
