import { cnLayout, Layout } from '@/blocks/layout'
import { Outlet, useLocation } from 'react-router-dom'
import { Map, MAP_INITIAL_OPTIONS, MapProvider } from '@/blocks/map'
import { useCallback, useContext, useEffect, useState } from 'react'
import maplibregl, { MapLibreEvent, StyleSpecification } from 'maplibre-gl'
import style from './layout_atlas.module.sass'
import { styled } from '@/utils/block'
import { HeaderAtlas } from '@/blocks/header/_atlas'
import { MapSpace } from '@/blocks/map-space'
import { MapControlGroup } from '@/blocks/map-control-group'
import { MapControlZoomIn } from '@/blocks/map-control/_zoom-in'
import { MapControlZoomOut } from '@/blocks/map-control/_zoom-out'
import { ThemeContext } from '@/blocks/theme'
import { getVectorStyleAtlas } from '@/blocks/map/map.helpers'
import { cnHeader } from '@/blocks/header'
import { ModalBase } from '@/blocks/modal/_base'
import { CardModal } from '@/blocks/card/_modal'
import { MENU_ATLAS, MenuAtlas } from '@/blocks/menu/_atlas'

styled(style)

export function LayoutAtlas(): JSX.Element {
  const [map, setMap] = useState<maplibregl.Map>()
  const [theme] = useContext(ThemeContext)
  const [mapStyle, setMapStyle] = useState<StyleSpecification | string>()
  const [zoom, setZoom] = useState<number>(0)
  const location = useLocation()
  const [modalIsShow, setModalIsShow] = useState<boolean>(false)

  // @ts-ignore
  const handleStyleImageMissing = (e) => {
    if (!map) return

    if (!map.hasImage(e.id)) {
      map.loadImage(`/images/${e.id}-pattern@2x.png`, (err, image) => {
        // if (err) throw err
        if (!map.hasImage(e.id)) {
          // @ts-ignore
          map.addImage(e.id, image)
        }
      })
    }
  }

  const handleMapZoom = (
    e: MapLibreEvent<MouseEvent | TouchEvent | WheelEvent | undefined> & Object
  ) => {
    if (!map) return
    setZoom(map.getZoom())
  }

  useEffect(() => {
    map?.on('styleimagemissing', handleStyleImageMissing)

    return () => {
      map?.off('styleimagemissing', handleStyleImageMissing)
    }
  }, [map])

  const handleMapLoaded = useCallback((map: maplibregl.Map) => {
    setZoom(map.getZoom())
    setMap(map)
    map?.touchPitch.disable()
  }, [])

  const handleZoomIn = () => {
    map?.flyTo({ center: map?.getCenter(), zoom: map?.getZoom() + 1 })
  }

  const handleZoomOut = () => {
    if (zoom > 3.45) {
      map?.flyTo({ zoom: map?.getZoom() - 1 })
    }
  }
  const handleCurrentClick = () => {
    setModalIsShow(true)
  }

  const handleModalRequestClose = () => {
    setModalIsShow(false)
  }

  const handleLinkClick = () => {
    setModalIsShow(false)
  }

  const currentItem = MENU_ATLAS.find(
    (item) =>
      item.path === location.pathname ||
      item.path === location.pathname.slice(0, -1) ||
      location.pathname.includes(item.selector as string)
  )

  useEffect(() => {
    if (!theme) return
    setMapStyle(getVectorStyleAtlas(theme))
  }, [theme])

  const maxBounds = new maplibregl.LngLatBounds(
    [1.8779327045625678, 28.726585838933076],
    [213.45202340041374, 82.67900486020596]
  )

  return (
    <Layout className={cnLayout({ atlas: true })}>
      <HeaderAtlas className={cnLayout('header')} title={'Атлас'}>
        <div className={cnHeader('navs')}>
          <div className={cnHeader('subtitle')}>Тип атласа</div>
          <div className={cnHeader('current')} onClick={handleCurrentClick}>
            <div className={cnHeader('text')}>{currentItem?.name}</div>

            <div className={cnHeader('arrow')}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.8">
                  <path
                    d="M7 11.5L12.5 15.5L18 11.5"
                    stroke="#E21A1A"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </g>
              </svg>
            </div>
          </div>
        </div>

        <ModalBase open={modalIsShow} toTop={true} onRequestClose={handleModalRequestClose}>
          <CardModal atlas={true}>
            <CardModal.Header>
              <CardModal.Close onClick={handleModalRequestClose} icon={true}>
                Закрыть
              </CardModal.Close>
            </CardModal.Header>
            <CardModal.Content>
              <MenuAtlas>
                {MENU_ATLAS.map((item) => (
                  <MenuAtlas.Item
                    key={item.path}
                    to={`${item.path}/?theme=${theme}`}
                    active={
                      item.path === location.pathname ||
                      item.path === location.pathname.slice(0, -1) ||
                      location.pathname.includes(item.selector as string)
                    }
                    onClick={handleLinkClick}
                  >
                    {item.name}
                  </MenuAtlas.Item>
                ))}
              </MenuAtlas>
            </CardModal.Content>
          </CardModal>
        </ModalBase>
      </HeaderAtlas>

      <Map
        className={cnLayout('map')}
        onLoaded={handleMapLoaded}
        onZoom={handleMapZoom}
        initialOptions={{
          ...MAP_INITIAL_OPTIONS,
          maxBounds,
          style: mapStyle as string,
          minZoom: 2.46,
        }}
        style={mapStyle}
      >
        <MapProvider map={map}>
          <MapSpace
            position={'left-bottom'}
            direction={'vertical'}
            className={cnLayout('left-bottom', { zoom: true })}
            pressed={true}
          >
            <MapControlGroup
              direction={'vertical'}
              gradient={true}
              rounded={true}
              className={cnLayout('zoom-controls')}
            >
              <MapControlZoomIn onClick={handleZoomIn} />
              <MapControlZoomOut onClick={handleZoomOut} disabled={zoom <= 3.45} />
            </MapControlGroup>
          </MapSpace>
          <Outlet />
        </MapProvider>
      </Map>
    </Layout>
  )
}
