import { Button, ButtonProps, cnButton } from '@/blocks/button'
import style from './button_default.module.sass'
import { styled } from '@/utils/block'

styled(style)

export type ButtonDefaultProps = ButtonProps

export function ButtonDefault(props: ButtonDefaultProps): JSX.Element {
  return <Button {...props} className={cnButton({ default: true }, [props.className])} />
}
