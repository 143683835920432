import { ReactNode, useEffect, useState } from 'react'
import ReactModal from 'react-modal'
import { Transition } from '@/blocks/transition'
import { styled, cn } from '@/utils/block'
import style from './modal.module.sass'

styled(style)

export const cnModal = cn('modal')

export type ModalProps = {
  open?: boolean
  className?: string
  children?: ReactNode
  entity?: string
  onRequestClose?: () => void
  shouldFocusAfterRender?: boolean
  shouldCloseOnOverlayClick?: boolean
  shouldCloseOnEsc?: boolean
}

export function Modal({
  open = false,
  entity = 'modal',
  children,
  className,
  onRequestClose,
  shouldFocusAfterRender = true,
  shouldCloseOnOverlayClick = true,
  shouldCloseOnEsc = true,
}: ModalProps): JSX.Element {
  const [show, setShow] = useState<boolean>(false)
  const [animate, setAnimate] = useState<boolean>(false)

  const handleAfterOpen = () => {
    setAnimate(true)
  }

  const handleExited = () => {
    setShow(false)
  }

  const handleRequestClose = () => {
    onRequestClose && onRequestClose()
  }

  useEffect(() => {
    if (open) setShow(true)
    if (!open) setAnimate(false)
  }, [open])

  return (
    <ReactModal
      portalClassName={cnModal({}, [className])}
      overlayClassName={cnModal('overlay', { show: animate })}
      className={cnModal('content')}
      isOpen={show}
      shouldFocusAfterRender={shouldFocusAfterRender}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      shouldCloseOnEsc={shouldCloseOnEsc}
      onRequestClose={handleRequestClose}
      onAfterOpen={handleAfterOpen}
    >
      <Transition
        entity={entity}
        timeout={300}
        show={animate}
        onExited={handleExited}
        unmountOnExit
      >
        <div className={cnModal('inner')}>{children}</div>
      </Transition>
    </ReactModal>
  )
}
