import style from './map-control-group.module.sass'
import { cn, styled } from '@/utils/block'
import { ReactNode } from 'react'

styled(style)

export type MapControlGroupProps = {
  className?: string
  children?: ReactNode
  direction?: 'vertical' | 'horizontal'
  gradient?: boolean
  rounded?: boolean
}

export const cnMapControlGroup = cn('map-control-group')

export function MapControlGroup({
  className,
  children,
  direction = 'horizontal',
  gradient,
  rounded,
}: MapControlGroupProps): JSX.Element {
  return (
    <div
      className={cnMapControlGroup({ [direction]: !!direction, gradient, rounded }, [className])}
    >
      {children}
    </div>
  )
}
