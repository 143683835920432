export const MENU_ATLAS = [
  {
    path: '/atlas/zheleznaya-doroga',
    name: 'Сооружение железных дорог',
  },
  {
    path: '/atlas/podgotovka-kadrov',
    name: 'Подготовка кадров для отрасли',
  },
  {
    path: '/atlas/gruzovie-perevozki',
    name: 'Грузовые перевозки. Общие сведения',
  },
  {
    path: '/atlas/poligonnyh-principy',
    name: 'Полигонные принципы управления',
  },
  {
    path: '/atlas/klimat-rossii',
    name: 'Климат России',
  },
  {
    path: '/atlas/passazhirskie-perevozki',
    name: 'Пассажирские перевозки',
  },
  {
    path: '/atlas/toplivno-energ-resursy',
    name: 'Топливно-энергетические ресурсы',
  },
  {
    path: '/atlas/elektroenergetika',
    name: 'Электроэнергетика',
  },
  {
    path: '/atlas/mineralnye-resursy',
    name: 'Минеральные ресурсы',
  },
  {
    path: '/atlas/istoricheskoe-nasledie',
    name: 'Историческое наследие',
  },
  {
    path: '/atlas/snezhnyj-pokrov-chislo-dnei',
    name: 'Снежный покров. Метели',
    selector: 'snezhnyj',
    subs: [
      {
        path: '/atlas/snezhnyj-pokrov-chislo-dnei',
        name: 'Число дней со снежным покровом',
      },
      {
        path: '/atlas/snezhnyj-pokrov-visota',
        name: 'Высота снежного покрова',
      },
      {
        path: '/atlas/snezhnyj-pokrov-otlojenia',
        name: 'Гололёдно-изморозевые отложения',
      },
      {
        path: '/atlas/snezhnyj-pokrov-meteli',
        name: 'Метели',
      },
    ],
  },
  /*{
    path: '/atlas/snezhnyj-pokrov-visota',
    name: 'Снежный покров. Метели 2',
  },
  {
    path: '/atlas/snezhnyj-pokrov-otlojenia',
    name: 'Снежный покров. Метели 3',
  },
  {
    path: '/atlas/snezhnyj-pokrov-meteli',
    name: 'Снежный покров. Метели 4',
  },*/
  {
    path: '/atlas/temperatury-sred-vozduha',
    name: 'Температуры',
    selector: 'temperatury',
    subs: [
      {
        path: '/atlas/temperatury-sred-vozduha',
        name: 'Среднегодовая температура воздуха',
      },
      {
        path: '/atlas/temperatury-min-sred-vozduha',
        name: 'Минимальная среднегодовая температура воздуха',
      },
      {
        path: '/atlas/temperatury-max-sred-vozduha',
        name: 'Максимальная среднегодовая температура воздуха',
      },
      {
        path: '/atlas/temperatury-sred-menee-8',
        name: 'Число дней со среднесуточной температурой воздуха менее +8°С',
      },
    ],
  },
  /*{
    path: '/atlas/temperatury-min-sred-vozduha',
    name: 'Температуры 2',
  },
  {
    path: '/atlas/temperatury-max-sred-vozduha',
    name: 'Температуры 3',
  },
  {
    path: '/atlas/temperatury-sred-menee-8',
    name: 'Температуры 4',
  }*/ {
    path: '/atlas/elektrifikaciya',
    name: 'Электрификация и электроснабжение',
  },
  {
    path: '/atlas/putevoe-hozyajstvo',
    name: 'Путь и путевое хозяйство',
  },
  {
    path: '/atlas/proizvodstvo-produkcii',
    name: 'Производство продукции для железных дорог',
  },
  {
    path: '/atlas/prigorodnoe-passazh-soobshchenie',
    name: 'Пригородное и межобластное пассажирское сообщение',
  },
]
