import { cnCard } from '@/blocks/card'
import { ReactNode } from 'react'
import style from './card__grid.module.sass'
import { styled } from '@/utils/block'

styled(style)

type CardGridProps = {
  children?: ReactNode
  className?: string
}

export function CardGrid({ children, className }: CardGridProps): JSX.Element {
  return <div className={cnCard('grid', {}, [className])}>{children}</div>
}
