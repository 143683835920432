import { cnModal, Modal, ModalProps } from '@/blocks/modal'
import style from './modal_base.module.sass'
import { styled } from '@/utils/block'

styled(style)

type ModalBaseProps = {
  hideOverflow?: boolean
  flat?: boolean
  className?: string
  toTop?: boolean
} & ModalProps

export function ModalBase({
  open,
  onRequestClose,
  children,
  flat,
  hideOverflow,
  toTop,
  className,
}: ModalBaseProps): JSX.Element {
  return (
    <Modal
      open={open}
      onRequestClose={onRequestClose}
      className={cnModal({ base: true, overflow_hide: hideOverflow, flat, top: toTop }, [
        className,
      ])}
    >
      {children}
    </Modal>
  )
}
