import { cnCard } from '@/blocks/card'
import { ReactNode } from 'react'
import style from './card__text.module.sass'
import { styled } from '@/utils/block'

styled(style)

type CardTextProps = {
  children?: ReactNode
  second?: boolean
}

export function CardText({ children, second }: CardTextProps): JSX.Element {
  return <div className={cnCard('text', { second })}>{children}</div>
}
