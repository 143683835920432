import { ReactNode } from 'react'
import { cn } from '@/utils/block'

export const cnMenu = cn('menu')

export type MenuProps = {
  children?: ReactNode
  className?: string
}

export function Menu({ children, className }: MenuProps) {
  return <div className={cnMenu({}, [className])}>{children}</div>
}
