import { ReactNode } from 'react'
import { CSSTransition } from 'react-transition-group'
import { styled, cn } from '@/utils/block'
import style from './transition.module.sass'

styled(style)

export const cnTransition = cn('tn')

type TransitionProps = {
  show: boolean
  entity: string
  timeout?: number
  unmountOnExit?: boolean
  children: ReactNode
  onExited?: () => void
  onEnter?: () => void
}

export function Transition({
  show,
  entity,
  timeout = 600,
  children,
  ...args
}: TransitionProps): JSX.Element {
  return (
    <CSSTransition
      in={show}
      classNames={{
        enter: cnTransition({ [entity]: 'enter' }),
        enterActive: cnTransition({ [entity]: 'enter-active' }),
        exit: cnTransition({ [entity]: 'exit' }),
        exitActive: cnTransition({ [entity]: 'exit-active' }),
      }}
      timeout={timeout}
      {...args}
    >
      <div>{children}</div>
    </CSSTransition>
  )
}
