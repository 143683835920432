import { LngLatLike, StyleSpecification } from 'maplibre-gl'

export const TILE_TOKEN = 'elNXhnGyvNp9N0Oj9Mus'
export const STYLE = {
  version: 8,
  sources: {},
  layers: [],
} as StyleSpecification

export const MAP_INITIAL_OPTIONS = {
  zoom: 3,
  renderWorldCopies: true,
  center: [82.81, 56.89] as LngLatLike,
  style: STYLE,
  hash: true,
}
